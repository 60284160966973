/*
 * @ Variables
 *
*/
/*
 *  Global border-box
*/
/*
 *  Base Container
*/
/*
 *  Generic Colors
*/
/*
 *  Z-index Of components
*/
/* Please do not give z-index more than these z-Index */
/*
 *  Farah Base primary colors
*/
/*
 *  Farah Supporting colors
*/
/*
 *  Yas WaterWorld Base primary colors
*/
/*
 *  Yas Island Base primary colors
*/
/*
 *  Yas Ferrari World Base primary colors
*/
/*
 *  Warner Bros World Base primary colors
*/
/*
 * Farah Font/buttons Color
*/
/*
 * Farah Font/buttons Color
*/
/*
 *  Farah Typograpy
*/
/*
 *  Farah base Font size
*/
/*
 *  Farah desktop heading sizes
*/
/*
 *  Farah mobile heading sizes
*/
/*
 *  Farah desktop font Sizes
*/
/*
 *  Farah mobile font Sizes
*/
/*
 *  Farah heading weight sizes
*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  Base Alignments
*/
/**
tab with placeholder
*/
/*
 * header fonts large desktop
 */
/*
 * header fonts tablets(landscape) and small desktop
 */
/*
 * header fonts tablets(portrait)
 */
/*
 * header fonts mobile devices
 */
/*
 * body-copy fonts large desktops
 */
/*
 * body-copy fonts tablets(landscape) and small desktops
 */
/*
 * body-copy fonts tablets(portrait)
 */
/*
 * body-copy fonts mobile devices
 */
/*
 * payment page tooltip
 */
/*
* Breakpoint
* @ Variables
*
*/
/*
 * @ Mixins
 *
*/
/*
 * Background Image
 */
/*
  * Configure vendor
  */
/*
  * Line height font
  */
/*
  * Clearfix
  */
/*
  * Text Nowrap
  */
/*
  * Font Smoothing
  */
/*
  * Transition
     usage example
       a{
       color: gray;
       @include transition(color .3s ease);
       &:hover {
         color: black;
       }
 }
  */
/*
  * Background Gradient
  */
/*
  * Background Horizontal
  */
/*
  * Background Radial
  */
/*
  * Background Size
  */
/*
  * Background Color Opacity
  */
/*
  * Border Radius
  */
/*
  * Border Radius Separate
  */
/*
  * Box
  */
/*
  * Box Rgba
  */
/*
  * Box Shadow
  */
/*
  * Box Sizing
  */
/*
  * Columns
  */
/*
  * Flex
  */
/*
  * Flip
  */
/*
  * Font Face
  */
/*
  * Opacity
  */
/*
  * Resize
  */
/*
  * Rotate
  */
/*
  * Text Shadow
  */
/*
  * Transform
  */
/*
  * Keyframes
    Usage Example
     @include keyframes(slide-down) {
       0% { opacity: 1; }
       90% { opacity: 0; }
     }
  */
/*
  * Animation
  Usage Example
    .element {
       @include animation('slide-down 5s 3');
     }
  */
/*
  * Widescreen
  */
/* common mixins below */
/*
  * Mobile
  */
/*
  * Tablet
  */
/*
  * Desktop (PC's)
  */
/* common mixins above */
/* typography specific mixins below */
/* typography specific mixins above */
/*
 * @ Colors
 *
*/
/* Colors */
/* footer */
/* Editoiral Grid */
/* Search */
/*
 * @ Text
 *
*/
/*
 * @ Gradients
 *
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: opacity(1);
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: opacity(1);
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: opacity(1);
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: opacity(1);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: opacity(1);
  }
}
.c-header {
  background: #1e1b3a;
  background: -webkit-linear-gradient(90deg, #540b79, #ff1857 100%);
  background: -o-linear-gradient(90deg, #540b79, #ff1857 100%);
  background: -moz-linear-gradient(90deg, #540b79, #ff1857 100%);
  background: linear-gradient(90deg, #540b79, #ff1857 100%);
  /* Medium devices (desktops, 1024px and up) */
}
.t-waterworld .c-header {
  background-color: #4b11ea;
  background: -webkit-linear-gradient(90deg, #4b11ea, #3cc 100%);
  background: -o-linear-gradient(90deg, #4b11ea, #3cc 100%);
  background: -moz-linear-gradient(90deg, #4b11ea, #3cc 100%);
  background: linear-gradient(90deg, #4b11ea, #3cc 100%);
}
.t-ferrari .c-header {
  background-color: #ba0703;
  background: -webkit-linear-gradient(90deg, #ba0703, #f20000 100%);
  background: -o-linear-gradient(90deg, #ba0703, #f20000 100%);
  background: -moz-linear-gradient(90deg, #ba0703, #f20000 100%);
  background: linear-gradient(90deg, #ba0703, #f20000 100%);
}
.t-yasisland .c-header {
  background-color: #540b79;
  background: -webkit-linear-gradient(90deg, #540b79, #ff1857 100%);
  background: -o-linear-gradient(90deg, #540b79, #ff1857 100%);
  background: -moz-linear-gradient(90deg, #540b79, #ff1857 100%);
  background: linear-gradient(90deg, #540b79, #ff1857 100%);
}
.t-wbw .c-header {
  background-color: #fbc531;
  background: -webkit-linear-gradient(90deg, #fbc531, #ffe400 100%);
  background: -o-linear-gradient(90deg, #fbc531, #ffe400 100%);
  background: -moz-linear-gradient(90deg, #fbc531, #ffe400 100%);
  background: linear-gradient(90deg, #fbc531, #ffe400 100%);
}
.t-register .c-header, .t-contactus .c-header {
  background-color: #0aaeec;
  background: -webkit-linear-gradient(90deg, #0aaeec, #540b79 100%);
  background: -o-linear-gradient(90deg, #0aaeec, #540b79 100%);
  background: -moz-linear-gradient(90deg, #0aaeec, #540b79 100%);
  background: linear-gradient(90deg, #0aaeec, #540b79 100%);
}
.t-tnc .c-header, .t-privacy-policy .c-header {
  background-color: #ff1857;
  background: -webkit-linear-gradient(90deg, #ff1857, #ff8f00 100%);
  background: -o-linear-gradient(90deg, #ff1857, #ff8f00 100%);
  background: -moz-linear-gradient(90deg, #ff1857, #ff8f00 100%);
  background: linear-gradient(90deg, #ff1857, #ff8f00 100%);
}
@media only screen and (min-width: 1024px) {
  .c-header {
    height: 80px;
  }
  .c-header-top {
    min-height: 80px;
    display: flex;
    align-items: center;
  }
  .c-header-top-icon {
    margin: 0 10px;
  }
}
.experience-editor-mode .c-header {
  position: absolute;
}
.c-header-top .profile,
.c-header-top .close {
  min-width: 30px;
  width: 30px;
  height: 30px;
}
.c-header .nav-item-search .overlay-wrapper {
  -webkit-transform: translate(0, 0);
  -khtml-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  background: #1e1b3a;
  background: -webkit-linear-gradient(90deg, #540b79, #ff1857 100%);
  background: -o-linear-gradient(90deg, #540b79, #ff1857 100%);
  background: -moz-linear-gradient(90deg, #540b79, #ff1857 100%);
  background: linear-gradient(90deg, #540b79, #ff1857 100%);
}
.t-waterworld .c-header .nav-item-search .overlay-wrapper {
  background-color: #4b11ea;
  background: -webkit-linear-gradient(90deg, #4b11ea, #3cc 100%);
  background: -o-linear-gradient(90deg, #4b11ea, #3cc 100%);
  background: -moz-linear-gradient(90deg, #4b11ea, #3cc 100%);
  background: linear-gradient(90deg, #4b11ea, #3cc 100%);
}
.t-ferrari .c-header .nav-item-search .overlay-wrapper {
  background-color: #ba0703;
  background: -webkit-linear-gradient(90deg, #ba0703, #f20000 100%);
  background: -o-linear-gradient(90deg, #ba0703, #f20000 100%);
  background: -moz-linear-gradient(90deg, #ba0703, #f20000 100%);
  background: linear-gradient(90deg, #ba0703, #f20000 100%);
}
.t-yasisland .c-header .nav-item-search .overlay-wrapper {
  background-color: #540b79;
  background: -webkit-linear-gradient(90deg, #540b79, #ff1857 100%);
  background: -o-linear-gradient(90deg, #540b79, #ff1857 100%);
  background: -moz-linear-gradient(90deg, #540b79, #ff1857 100%);
  background: linear-gradient(90deg, #540b79, #ff1857 100%);
}
.t-wbw .c-header .nav-item-search .overlay-wrapper {
  background-color: #fbc531;
  background: -webkit-linear-gradient(90deg, #fbc531, #ffe400 100%);
  background: -o-linear-gradient(90deg, #fbc531, #ffe400 100%);
  background: -moz-linear-gradient(90deg, #fbc531, #ffe400 100%);
  background: linear-gradient(90deg, #fbc531, #ffe400 100%);
}
.t-register .c-header .nav-item-search .overlay-wrapper, .t-contactus .c-header .nav-item-search .overlay-wrapper {
  background-color: #0aaeec;
  background: -webkit-linear-gradient(90deg, #0aaeec, #540b79 100%);
  background: -o-linear-gradient(90deg, #0aaeec, #540b79 100%);
  background: -moz-linear-gradient(90deg, #0aaeec, #540b79 100%);
  background: linear-gradient(90deg, #0aaeec, #540b79 100%);
}
.t-tnc .c-header .nav-item-search .overlay-wrapper, .t-privacy-policy .c-header .nav-item-search .overlay-wrapper {
  background-color: #ff1857;
  background: -webkit-linear-gradient(90deg, #ff1857, #ff8f00 100%);
  background: -o-linear-gradient(90deg, #ff1857, #ff8f00 100%);
  background: -moz-linear-gradient(90deg, #ff1857, #ff8f00 100%);
  background: linear-gradient(90deg, #ff1857, #ff8f00 100%);
}
.c-header .nav-item-search .overlay-wrapper .overlay--close-button {
  background: transparent;
}
.c-header .nav-item-search .overlay-wrapper .overlay-content-container {
  width: 100%;
}
.c-header .autocomplete-wrapper {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .autocomplete-wrapper {
    animation: 600ms ease-in 0s 1 fadeIn;
  }
}
.c-header .autocomplete-wrapper .btn-submit {
  border: solid 1px #333;
  /* Medium devices (desktops, 1024px and up) */
}
.c-header .autocomplete-wrapper .btn-submit:hover {
  background-color: transparent;
  color: #fff;
  border: 1px solid #333;
}
@media only screen and (min-width: 1024px) {
  .c-header .autocomplete-wrapper .btn-submit {
    background-color: #333;
    color: white;
  }
  .c-header .autocomplete-wrapper .btn-submit:hover {
    background-color: #fff;
    color: #333;
    border: 1px solid #333;
  }
}
.c-header .autocomplete-wrapper .btn-submit .svg-use {
  fill: #333;
}
.c-header .autocomplete-wrapper .btn-submit.disabled:hover, .c-header .autocomplete-wrapper .btn-submit[disabled]:hover {
  filter: alpha(opacity=10);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
  -webkit-opacity: 0.1;
  -khtml-opacity: 0.1;
  -moz-opacity: 0.1;
  -ms-opacity: 0.1;
  -o-opacity: 0.1;
  opacity: 0.1;
  background-color: transparent;
  color: #fff;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .autocomplete-wrapper .btn-submit.disabled:hover, .c-header .autocomplete-wrapper .btn-submit[disabled]:hover {
    background-color: #333;
    color: #fff;
  }
}
.c-header .autocomplete-wrapper .input-container {
  background-color: #fff;
}
.c-header .autocomplete-wrapper .form-input {
  caret-color: rgba(213, 213, 213, 0.5);
  color: #333;
}
.c-header .autocomplete-wrapper .form-input::-webkit-input-placeholder {
  color: #d5d5d5;
  opacity: 0.5;
}
.c-header .autocomplete-wrapper .form-input:-moz-placeholder {
  color: #d5d5d5;
  opacity: 0.5;
}
.c-header .autocomplete-wrapper .form-input::-moz-placeholder {
  color: #d5d5d5;
  opacity: 0.5;
}
.c-header .autocomplete-wrapper .form-input:-ms-input-placeholder {
  color: #d5d5d5;
  opacity: 0.5;
}
.c-header .autocomplete-wrapper .form-input::placeholder {
  color: #d5d5d5;
  opacity: 0.5;
}
.c-header .c-header-wrapper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  align-items: center;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .c-header-wrapper .header-body {
    width: 67%;
  }
  .c-header .c-header-wrapper .header-bottom {
    width: 33%;
  }
}
.c-header .header-logged .header-body {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .header-logged .header-body {
    width: 67%;
  }
}

.t-wbw .header-pre-logged {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .t-wbw .header-pre-logged .c-header-wrapper .header-body-rte {
    color: #333;
  }
}